import ContactForm from "./ContactForm";
import TextSection from "./TextSection";
import PageTitle from "./PageTitle";
import { Map, Marker } from "react-map-gl";
import Button from "./Button";
import "../styling/ContactUsPage.css";
import mapboxgl from "mapbox-gl";
import BackgroundImage from "./BackgroundImage";

mapboxgl.accessToken = "pk.eyJ1IjoiYXRoeW5hIiwiYSI6ImNsaDlobzEzdDA3aWgzcW8xdjZncmp3cGUifQ.SsQ4-kM7XHflgt6kerdhRw";

const contactDetails = [
  {
    title: "Main Office",
    contactInfo: [{ link: "tel:204-232-3792", text: "(204) 232-3792" }],
  },
  {
    title: "Sales And Estimations",
    contactInfo: [
      { link: "tel:204-202-7848", text: "(204) 202-7848" },
      { link: "mailto:patrick@phoenixcrane.ca", text: "patrick@phoenixcrane.ca" },
    ],
  },
  {
    title: "Operations Manager",
    contactInfo: [
      { link: "tel:204-202-7847", text: "(204) 202-7847" },
      { link: "mailto:ayla@phoenixcrane.ca", text: "ayla@phoenixcrane.ca" },
    ],
  },
  {
    title: "Project Management",
    contactInfo: [
      { link: "tel:204-232-3792", text: "(204) 232-3792" },
      { link: "mailto:gregg@phoenixcrane.ca", text: "gregg@phoenixcrane.ca" },
    ],
  },
  {
    title: "Chief Financial Officer",
    contactInfo: [
      { link: "tel:204-202-7846", text: "(204) 202-7846" },
      { link: "mailto:jordan@phoenixcrane.ca", text: "jordan@phoenixcrane.ca" },
    ],
  },
];

function ContactUsPage() {
  return (
    <main>
      <PageTitle title={"Contact Us"} image={"/images/contact-us-page/banner/contact-us-banner.webp"} />
      <div className="contact-container">
        <ContactForm />
        <div className="contact-section">
          <div>
            {contactDetails.map((item, index) => (
              <div className="contact-person" key={index}>
                <TextSection paragraphs={[{ text: item.title }]} />
                <div className="contact-info">
                  {item.contactInfo.map((contact, index) => (
                    <a href={contact.link} key={index}>
                      {contact.text}
                    </a>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <TextSection
            header={"Business Hours"}
            paragraphs={[{ text: "Monday - Friday: 8:00am - 5:00pm" }, { text: "Saturday - Sunday: Closed" }]}
          />
        </div>
        <BackgroundImage link="/images/logos/silver-phoenix-background.png" />
      </div>

      <div className="map">
        <Map
          mapLib={import("mapbox-gl")}
          initialViewState={{
            longitude: -97.15472,
            latitude: 49.80926,
            zoom: 13,
          }}
          style={{ width: "100%", height: 400 }}
          mapStyle="mapbox://styles/mapbox/streets-v12">
          <Button
            text={"Get Directions"}
            link="https://www.google.com/maps?daddr=206-2265+Pembina+Hwy,+Winnipeg,+Manitoba+R3T+3L6,+Canada"
          />
          <Marker longitude={-97.15472} latitude={49.80926} anchor="bottom" color="var(--primary-color)"></Marker>
        </Map>
      </div>
    </main>
  );
}

export default ContactUsPage;
